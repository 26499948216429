import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Image from "../../images/intern.png";
import WebImage from "../../images/web-intern.png";
import BackImage from "../../images/back-intern.png";
import InternGif from "../../images/join-team.gif";
import AIImage from "../../images/ai-intern.png";
import { ButtonForm } from "../ButtonElements";
import { InternContainer, InternWrapper, InternContent, InternCard, H4, InternContentInfo, H2, P, InternContentCard, Button, ButtonV } from './InternsElements';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const Interns = () => {
  const [show, setShow] = useState(false);
  const [popupContent, setPopupContent] = useState({ title: "", details: "", formLink: "" });
  const [showAll, setShowAll] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (title, details, formLink) => {
    setPopupContent({ title, details, formLink });
    setShow(true);
  };

  const internCards = [
    {
      image: BackImage,
      title: "Full Stack Developer",
      location: "Navi Mumbai",
      type: "Full Time",
      details: <>
      <ul>
      <h4>Role Overview:</h4>
        <li>Position: Full Stack Developer</li>
        <li>Location: Navi Mumbai</li>
        <li>Type: Full Time</li>
        <li>Experience: 1 - 4 years (relevant experience in full-stack development)</li>
        <li>Education: Any Graduate</li>
      </ul>
      <ul>
      <h4>Responsibilities:</h4>
        <li>Collaborate with designers and back-end developers to implement user-facing features.</li>
        <li>Translate UI/UX designs into high-quality code.</li>
        <li>Optimize web applications for performance, scalability, and adherence to web standards.</li>
        <li>Ensure seamless integration with back-end systems.</li>
      </ul>
      <ul>
      <h4>Qualifications:</h4>
        <li>Proficiency in full-stack development principles (HTML, CSS, JavaScript).</li>
        <li>Strong understanding of front-end and back-end development principles.</li>
        <li>Experience with REST APIs and asynchronous programming.</li>
        <li>Familiarity with version control systems (e.g., Git).</li>
        <li>Attention to detail and a passion for creating exceptional user experiences.</li>
      </ul>
      <ul>
      <h4>Benefits:</h4>
        <li>Competitive salary and benefits package.</li>
        <li>Opportunities for career growth and skill development.</li>
        <li>Collaborative work environment.</li>
        <li>Work-life balance.</li>
      </ul>
      </>,
      formLink: "http://itms.xpertlyte.com/register"
    },
    {
      image: WebImage,
      title: "Web Developer",
      location: "Remote",
      type: "Internship",
      details: <>
      <ul>
      <h4>Role Overview:</h4>
        <li>Position: Web Developer</li>
        <li>Location: Remote</li>
        <li>Type: Internship</li>
        <li>Experience: 0 - 2 years (relevant experience in web development)</li>
        <li>Education: Any Graduate</li>
      </ul>
      <ul>
      <h4>Responsibilities:</h4>
        <li>Develop and maintain web applications using modern web technologies.</li>
        <li>Collaborate with designers to implement visually appealing and user-friendly interfaces.</li>
        <li>Optimize web applications for performance, scalability, and adherence to web standards.</li>
        <li>Ensure seamless integration with back-end systems.</li>
      </ul>
      <ul>
      <h4>Qualifications:</h4>
        <li>Proficiency in web development principles (HTML, CSS, JavaScript).</li>
        <li>Strong understanding of front-end development principles.</li>
        <li>Experience with modern web frameworks and libraries (e.g., React, Angular, Vue.js).</li>
        <li>Familiarity with version control systems (e.g., Git).</li>
        <li>Attention to detail and a passion for creating exceptional user experiences.</li>
      </ul>
      <ul>
      <h4>Benefits:</h4>
        <li>Competitive stipend and benefits package.</li>
        <li>Opportunities for career growth and skill development.</li>
        <li>Collaborative work environment.</li>
        <li>Work-life balance.</li>
      </ul>
    </>,
      formLink: "http://itms.xpertlyte.com/register"
    },
    {
      image: AIImage,
      title: "AI Developer",
      location: "Remote",
      type: "Internship",
      details: <>
      <ul>
      <h4>Role Overview:</h4>
        <li>Position: AI Developer</li>
        <li>Location: Remote</li>
        <li>Type: Internship</li>
        <li>Experience: 0 - 2 years (relevant experience in AI/ML development)</li>
        <li>Education: Any Graduate</li>
      </ul>
      <ul>
      <h4>Responsibilities:</h4>
        <li>Design, develop, and deploy AI/ML models using popular frameworks such as TensorFlow, PyTorch, or Keras.</li>
        <li>Collaborate with data scientists to develop and implement AI/ML solutions.</li>
        <li>Optimize AI/ML models for performance, scalability, and accuracy.</li>
        <li>Ensure seamless integration with other systems and applications.</li>
      </ul>
      <ul>
      <h4>Qualifications:</h4>
        <li>Proficiency in AI/ML development principles and frameworks.</li>
        <li>Strong understanding of machine learning algorithms and data structures.</li>
        <li>Experience with data preprocessing, feature engineering, and model evaluation.</li>
        <li>Familiarity with version control systems (e.g., Git).</li>
        <li>Attention to detail and a passion for creating innovative AI/ML solutions.</li>
      </ul>
      <ul>
      <h4>Benefits:</h4>
        <li>Competitive stipend and benefits package.</li>
        <li>Opportunities for career growth and skill development.</li>
        <li>Collaborative work environment.</li>
        <li>Work-life balance.</li>
      </ul>
    </>,
      formLink: "http://itms.xpertlyte.com/register"
    },
    // {
    //   image: AIImage,
    //   title: "UI/UX Designer",
    //   location: "Remote",
    //   type: "Internship",
    //   details: "As a UI/UX designer at XpertLyte, you will be responsible for crafting intuitive and engaging user experiences for web and mobile applications. Your role will involve conducting user research, creating user personas, and designing wireframes, prototypes, and high-fidelity mockups using tools such as Sketch, Figma, or Adobe XD. You will collaborate with product managers, developers, and other stakeholders to gather requirements and ensure that designs meet user needs and business goals. Additionally, you will conduct usability testing and analyze user feedback to iteratively improve the design. Your responsibilities will also include maintaining design consistency through style guides and design systems, staying current with industry trends and best practices, and continuously advocating for user-centered design principles throughout the development process.",
    //   formLink: "https://docs.google.com/forms/u/1/d/e/1FAIpQLSdh2LDA1sz3T3SoXZHC906MAnOjKEwwIasK2aL_Pq3_r7cOow/viewform"
    // }
  ];

  return (
    <InternContainer>
      <InternWrapper className="box">
        <img src={InternGif} style={{ width: "55%", height: "100%", paddingTop: "80px", marginLeft: "50px" }} className="des-bord" alt="Intern Gif"></img>
        <div>
          <h1 style={{ marginLeft: "50px", fontWeight: "bold", color: "#e0703b", marginRight: "10px", marginTop: "20px" }} className="join">Are you passionate about technology? Eager to make an impact?</h1>
          <H4>At XpertLyte, we’re on a mission to revolutionize the IT landscape. Join our dynamic team and be part of something extraordinary.</H4>
        </div>
      </InternWrapper>
      <InternContent>
        <InternContentInfo>
          <H2>Current Openings and Beyond</H2>
          <P>While specific positions may not always be posted, we encourage you to reach out! Send us a general inquiry along with your impressive portfolio. We’re always scouting for exceptional talent.</P>
          <H2>How to reach out?</H2>
          <ul>
            <li><strong>Email: </strong>Send your portfolio and a brief introduction to careers@xpertlyte.com.</li>
            <li><strong>Subject Line: </strong>Mention “General Inquiry - [Your Name]”.</li>
            <li><strong>Tell Us About You: </strong>Share your skills, interests, and what excites you about IT.</li>
            <li><strong>Portfolio: </strong>Showcase your best work—projects, code samples, or anything that reflects your expertise.</li>
          </ul>
        </InternContentInfo>
        <InternContentCard>
          {internCards.slice(0, showAll ? internCards.length : 3).map((intern, index) => (
            <InternCard key={index}>
              <div style={{ display: "flex", gap: "10px" }} className="card-ft">
                <img src={intern.image} style={{ height: "100px", width: "auto" }} alt={intern.title}></img>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} className="ft-loc-bot">
                  <h2 style={{ marginBottom: "20px", textAlign: "left" }}>{intern.title}</h2>
                  <div style={{ display: "flex", flexDirection: "row", textAlign: "left", alignItems: "center" }}>
                    <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: "18px", paddingRight: "6px" }} /> {intern.location}
                    <div></div>
                    <FontAwesomeIcon icon={faClipboard} style={{ fontSize: "18px", paddingRight: "6px", marginLeft: "10px" }} /> {intern.type}
                  </div>
                </div>
              </div>
              <ButtonForm as="a" primary big onClick={() => handleShow(intern.title, intern.details, intern.formLink)}>Join</ButtonForm>
            </InternCard>
          ))}
          {/* {!showAll && <ButtonV onClick={() => setShowAll(true)}>View All</ButtonV>} */}
        </InternContentCard>
      </InternContent>

      <Modal show={show} onHide={handleClose} style={{paddingLeft: "0"}} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title style={{fontWeight: "bold"}}>{popupContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-grid">{popupContent.details}</Modal.Body>
        <Modal.Footer>
          <ButtonForm as="a" primary big target="_blank" href={popupContent.formLink}>Apply</ButtonForm>
        </Modal.Footer>
      </Modal>
    </InternContainer>
  );
};

export default Interns;
