import React, { useState, useEffect } from "react";
import CooImage from "../images/cookie.png";
import styled from "styled-components";

const Cookie = () => {
  const [hideCookieBox, setHideCookieBox] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = document.cookie.split('; ').find(row => row.startsWith('cookieConsent='));
    if (!cookieConsent) {
      setIsVisible(true);
    }
    let checkCookie = document.cookie.indexOf("XpertLyte");
    checkCookie != -1 ? setHideCookieBox(true) : setHideCookieBox(false);
  }, []);

  const handleAccept = () => {
    document.cookie = "XpertLyte; max-age=" + 60 * 60 * 24 * 30;
    if (document.cookie) {
      setHideCookieBox(true);
    } else {
      alert("Cookie can't be set! Please unblock this site from the cookie setting of your browser.");
    }
    setIsVisible(false);
  };

  if (!isVisible) return null;
  
  const Wrapper = styled.div`
    position: fixed;
    bottom: 30px;
    left: 30px;
    max-width: 365px;
    background: #fff;
    padding: 25px 25px 30px 25px;
    border-radius: 15px;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.15);
    text-align: center;
    z-index: 100;

    @media screen and (max-width: 431px) {
      left: 20px;
      right: 20px;
    }

    ${hideCookieBox ? "opacity: 0; pointer-events: none; transform: scale(0.8); transition: all 0.3s ease;" : ""}
  `;

  const Img = styled.img`
    max-width: 90px;
  `;

  const Content = styled.div`
    margin-top: 10px;

    header {
      font-size: 25px;
      font-weight: 600;
    }

    p {
      color: #858585;
      margin: 5px 0 20px 0;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      padding: 4px 20px;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
      background: #E0703B;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    button:hover {
      transform: scale(0.97);
    }

    .item {
      margin: 0 10px;

      @media screen and (max-width: 431px) {
        margin: 0 5px;
      }
    }

    a {
      color: #ECA987;
    }
  `;

  return (
    <Wrapper className={hideCookieBox ? "hide" : ""}>
      <Img src={CooImage} alt="" />
      <Content>
        <header>Cookies Consent</header>
        <p>This website use cookies to ensure you get the best experience on our website.</p>
        <div className="buttons">
          <button className="item" onClick={handleAccept}>
            I understand
          </button>
          <a href="/privacy-policy" className="item">
            Learn more
          </a>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Cookie;