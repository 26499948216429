import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { ClientTestimonial, ClientName, ClientText } from "./clientElements";
import "../../App.css";

const ClientSection = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const testimonials = [
    { text: "Working with XpertLyte has been an absolute pleasure for us at Incrementum Healthcare. Their exceptional talent, dedication, and creativity have brought our vision to life in ways we couldn't have imagined. We're immensely grateful for their outstanding work, which has not only enhanced our online presence but also inspired confidence in our brand. Their professionalism and commitment to excellence are truly commendable, and we look forward to continued collaboration with them in the future.", name: "~ Incrementum Healthcare" },
    { text: "Embracing the collaboration with XpertLyte, we find ourselves deeply appreciative of their remarkable professionalism and dedication. Their meticulous attention to detail, innovative solutions, and unwavering commitment to excellence have significantly enhanced our project's success. We extend our sincere gratitude for their outstanding contributions, which have not only strengthened our brand but also inspired confidence in our endeavors. We eagerly anticipate further opportunities to partner with XpertLyte and continue our journey toward shared accomplishments.", name: "~ Centrum Global Solutions" }
  ];

  const carouselStyles = {
    indicators: {
      li: {
        color: "#000"
      }
    },
    controlPrev: {
      color: "#000"
    },
    controlNext: {
      color: "#000"
    }
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {testimonials.map((testimonial, index) => (
        <Carousel.Item key={index}>
          <ClientTestimonial>
            <ClientText>" {testimonial.text} "</ClientText>
            <ClientName>{testimonial.name}</ClientName>
          </ClientTestimonial>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ClientSection;
